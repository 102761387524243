import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductDetails,
  newReview,
  clearErrors,
} from "../../actions/productActions";
import { addItemToCart } from "../../actions/cartActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import Sliders from "../../components/Slider";
import { toast } from "react-toastify";
import { NEW_REVIEW_RESET } from "../../constants/productConstants";
import ReactStars from "react-rating-stars-component";
import Swal from "sweetalert2";
import ListReviews from "../../components/ListReviews";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Image, Tooltip } from "antd";
import { Radio } from 'antd';
import "../../styles/productdetails.css";

const ProductDetails = () => {
  const [quantity, setQuantity] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const { t } = useTranslation("product");

  const dispatch = useDispatch();

  const { id } = useParams();

  const { loading, error, product } = useSelector(
    (state) => state.productDetails
  );
  const { user } = useSelector((state) => state.auth);
  const { error: reviewError, success } = useSelector(
    (state) => state.newReview
  );

  const decreaseQty = () => {
    const count = document.querySelector(".count");

    if (count.valueAsNumber <= 1) return;

    const qty = count.valueAsNumber - 1;
    setQuantity(qty);
  };

  const increaseQty = () => {
    const count = document.querySelector(".count");

    if (count.valueAsNumber >= product.stock) return;

    const qty = count.valueAsNumber + 1;
    setQuantity(qty);
  };

  const addToCart = () => {
    dispatch(addItemToCart(id, quantity));

    toast.success(t("Item Added to Cart"), {
      position: toast.POSITION.TOP_RIGHT,
      className: "m-2",
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (rating === 0 || comment === "") {
      Swal.fire({
        title: "Error!",
        text: t("Rating and Comment are Required"),
        icon: "error",
        confirmButtonText: t("Ok"),
      });
    } else {
      dispatch(
        newReview({
          rating,
          comment,
          productId: id,
        })
      );
      setComment("");
    }
  };

  useEffect(() => {
    dispatch(getProductDetails(id));

    if (reviewError) {
      toast.error(reviewError, {
        position: toast.POSITION.TOP_RIGHT,
        className: "m-2",
      });
      dispatch(clearErrors());
    }

    if (success) {
      toast.success(t("Reivew posted successfully"), {
        position: toast.POSITION.TOP_RIGHT,
        className: "m-2",
      });
      dispatch({ type: NEW_REVIEW_RESET });
    }
  }, [dispatch, id, reviewError, success]);

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);
  var settings = {
    dots: true,

    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    autoplay: true,
    infinite: true,

    speed: 1000,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [value, setValue] = useState();
  useEffect(() => { setValue(product?.sizes?.[0]?.sizePrice) }, [product])
  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <section className="container my-4">
      {loading ? (
        <Loader />
      ) : error ? (
        <Message color="danger" message={error} />
      ) : (
        <>
          <div className="row d-flex justify-content-around">

            <div className="col-12 col-lg-5 img-fluid mt-4">
              <Sliders images={product?.images} />
              {/* <Sliders images={product?.certificates} width={100} /> */}
              <div style={{ display: "flex", gap: "10px", paddingTop: "10px", flexWrap: "wrap", justifyContent: "center" }}>
                {product?.certificates?.map((cert) => <Image fallback={"https://api.lagha.shop" + cert.path} style={{ width: "80px", height: "80px" }} />)}
              </div>
            </div>

            <div className="col-12 col-lg-5 mt-4">
              <h3>{product.name}</h3>
              <p>
                {" "}
                {t("Product")} code: {product.code}
              </p>

              <hr />

              <div className="d-flex align-items-end">
                <h4 className="mb-0">
                  DT {value ? Number(value).toFixed(2) : product.price && product.price.toFixed(2)}
                </h4>
                &nbsp;
                {product.oldPrice !== 0 && (
                  <h6 className="mb-0 text-muted">
                    <del>DT {product.oldPrice}</del>
                  </h6>
                )}
              </div>

              <hr />

              <div className="ratings mt-auto text-nowrap">
                <div className="rating-outer">
                  <div
                    className="rating-inner"
                    style={{ width: `${(product.ratings / 5) * 100}%` }}
                  ></div>
                </div>
                <small id="no_of_reviews">
                  &nbsp;({product.numOfReviews} {t("Reviews")})
                </small>
              </div>

              <hr />

              <b>
                {t("Status")}&nbsp;
                <span
                  className={product.stock > 0 ? "text-success" : "text-danger"}
                >
                  {product.stock > 0 ? t("In Stock") : t("Out of Stock")}
                </span>
              </b>

              <hr />

              <h4>{t("Description")}</h4>
              <p>{product.description}</p>

              <hr />
              {/* {product?.colors && (
                <div className="form-group" style={{display:"flex", gap:"5px"}}>
                  {product?.colors?.[0].split(",")?.map((color) => (
           
                  ))}
                </div>
              )} */}
              {product?.colors?.length > 0 && <> {product?.colors && <h4>{t("Colors")}</h4>}

                <div
                  className="promo-products-container"
                  style={{ padding: "25px" }}
                >
                  <Slider {...settings}>
                    {product?.colors &&
                      product?.colors?.map((color, index) => (
                        <Tooltip title={color?.name}>
                          <div key={index}>
                            <span
                              style={{
                                display: "inline-block",
                                width: "30px",
                                height: "30px",
                                backgroundColor: color?.value,
                                borderRadius: "50px",
                                margin: "5px",
                              }}
                            ></span>
                          </div>
                        </Tooltip>
                      ))}
                  </Slider>
                </div>              <hr />
              </>}


              <h4>{t("PACK/SIZE")}</h4>

              <Radio.Group onChange={onChange} value={value} defaultValue={product?.sizes?.[0]?.sizePrice}>

                {product?.sizes?.map((size) => <Radio key={size?.sizePrice} value={size?.sizePrice}><p style={{ fontFamily: "monospace" }}>{size?.sizeName}</p></Radio>
                )}
              </Radio.Group>
              <hr />

              <div className="row">
                <div className="col">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button
                        className="btn btn-sm btn-outline-danger px-3"
                        type="button"
                        disabled={product.stock === 0}
                        onClick={decreaseQty}
                      >
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      </button>
                    </div>

                    <input
                      type="number"
                      className="form-control form-control-sm text-center count"
                      value={quantity}
                      readOnly
                    />

                    <div className="input-group-prepend">
                      <button
                        className="btn btn-sm btn-outline-success px-3"
                        type="button"
                        disabled={product.stock === 0}
                        onClick={increaseQty}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <button
                    type="button"
                    className="btn btn-sm text-white w-100 h-100 text-nowrap"
                    style={{ backgroundColor: "#FF9D1C" }}
                    disabled={product.stock === 0}
                    onClick={addToCart}
                  >
                    <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                    &nbsp;&nbsp;{t("Add to Cart")}
                  </button>
                </div>
              </div>

              <hr />

              {/* <p>
								Sold by: <strong>{product.seller}</strong>
							</p> */}
            </div>
          </div>

          <hr />

          <div className="row d-flex justify-content-center">
            <div className="col-md-12">
              <div
                className="card shadow-0 border"
                style={{ backgroundColor: "#F6F9FC" }}
              >
                <div className="card-body p-4">
                  {user ? (
                    <form onSubmit={submitHandler}>
                      <h5 className="text-center">{t("WRITE A REVIEW")}</h5>

                      <div className="star-container text-center text-nowrap mb-3">
                        <div
                          className="star-widget text-nowrap"
                          style={{ display: "inline-block" }}
                        >
                          <ReactStars
                            count={5}
                            onChange={setRating}
                            size={32}
                            isHalf={false}
                            emptyIcon={<i className="far fa-star"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                            activeColor="#ffd700"
                            value={rating}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <textarea
                          className="form-control"
                          placeholder={t("Describe your experience.")}
                          style={{ minHeight: "100px" }}
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></textarea>
                      </div>

                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn py-2 text-white text-nowrap"
                          style={{
                            backgroundColor: "#FF9D1C",
                            borderRadius: "25px",
                          }}
                        >
                          {t("Submit")}
                        </button>
                      </div>
                    </form>
                  ) : (
                    <Message
                      color="warning"
                      message={t("Login to post your review.")}
                    />
                  )}

                  <hr />

                  {product && product.reviews && product.reviews.length > 0 ? (
                    <ListReviews reviews={product.reviews} />
                  ) : (
                    <h6 className="text-center">{t("No Reviews found!")}</h6>
                  )}
                </div>
              </div>
            </div>
          </div>

        </>
      )}
    </section>
  );
};

export default ProductDetails;
